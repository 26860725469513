// Icon with Text Style 01
.icon-with-text-01 {
  .feature-box-content {
    .title {
      color: #232323;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  >div {
    display: flex;
  }

  i {
    margin-right: 35px;
  }

  p {
    width: 80%;

    @media (max-width:var(--lg)) {
      width: 100%;
    }

    margin-bottom: 0;
  }
}

// Icon With Text Style 02
.icon-with-text-02 {
  display: flex;
  

  >div {
    background-color: white;
    display: flex;
    padding: 56px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    transition: var(--default-transition);

    @media (max-width:var(--md)) {
      padding: 30px;
    }

    @media (max-width:var(--xs)) {
      padding: 44px;
    }

    &:hover {
      -webkit-transform: translate3d(0, -3px, 0);
      transform: translate3d(0, -3px, 0);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
      transition: var(--default-transition);
    }
  }

  i {
    color: var(--base-color, #0038e3);
    font-size: 40px;
    margin-right: 35px;
  }

  .feature-box-content {
    .title {
      margin-bottom: 10px;
      display: block;
      color: #232323;
    }

    p {
      margin-bottom: 0;
    }
  }
}

//Icon With Text Style 03
.icon-with-text-03 {
  >div {
    padding: 1.5rem;

    @media (max-width:var(--sm)) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    @media (max-width:var(--xs)) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  i {
    display: inline-block;
    margin-bottom: 15px;

    @media (max-width:var(--md)) {
      margin-bottom: 15px;
    }

    @media (max-width:var(--sm)) {
      margin-bottom: 10px;
    }

  }

  .feature-box-content {
    .title {
      display: block;
      margin-bottom: 10px;
      color: #232323;
    }
  }
}

//Icon With Text Style 04
.icon-with-text-04 {
  >div {
    padding: 3rem;
    align-items: center;
    display: flex;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    transition: var(--default-transition);
    background-color: white;

    @media (max-width:var(--lg)) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media (max-width:var(--md)) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    &:hover {
      -webkit-transform: translate3d(0, -3px, 0);
      transform: translate3d(0, -3px, 0);
    }
  }

  i {
    margin-right: 30px;
    font-size: 40px;
    color: #3975f6;//var(--base-color, #0038e3);
  }

  .feature-box-content {
    .title {
      font-family: var(--alt-font);
      font-size: 18px;
      line-height: 22px;
      display: block;
      margin-bottom: 5px;
      color: #232323;

      @media (max-width:var(--md)) {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}

//Icon With Text Style 05
.icon-with-text-05 {
  >div {
    text-align: center;
    transition: var(--default-transition);

    &:hover {
      i {
        color: 3975f6;
        transition: var(--default-transition);
      }
    }
  }

  i {
    display: block;
    font-size: 50px;
    padding-bottom: 20px;
    transition: var(--default-transition);
  }

  .feature-box-content {
    .title {
      font-weight: 600;
      text-transform: uppercase;
      color: #232323;
    }
  }
}

//Icon With Text Style 06
.icon-with-text-06 {
  >div {
    border: 1px solid #e4e4e4;
    padding: 3rem;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition-duration: 0.4s;

    .feature-box-content {
      >span {
        margin-bottom: 10px;
        display: block;
      }

      .title {
        color: #232323;
      }
    }

    .icon-text {
      margin-bottom: 1.5rem;
      font-size: 40px;
      transition-duration: 0.4s;
    }

    &:hover {
      border-color: transparent;
      background-color: white;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);

      i {
        transform: translateY(-10px);
      }

      a {
        opacity: 1;
        transform: translateY(-6px);
      }

      .icon-text {
        transform: translateY(-10px);
      }

      .feature-box-content {
        transform: translateY(-10px);

        >span {
          margin-bottom: 10px;
          display: block;
        }

        .title {
          color: #232323;
        }
      }
    }
  }

  i {
    display: block;
    font-size: 40px;
    margin-bottom: 25px;
    transform: translateY(20px);
    transition-duration: 0.4s;
    color: 3975f6;
    transition: var(--default-transition);
  }

  a {
    color: var(--base-color);
    opacity: 0;
    transition-duration: 0.4s;
    transform: translateY(20px);
  }

  .feature-box-content {
    transform: translateY(20px);
    transition-duration: 0.4s;
    margin-bottom: 15px;
  }
}

//Icon With Text Style 07
.icon-with-text-07 {
  >div {
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: var(--default-transition);
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width:var(--lg)) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media (max-width:var(--sm)) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media (max-width:var(--md)) {
      margin-bottom: 50px;
    }

    &:hover {
      i {
        color: white;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .feature-box-content {
    .title {
      display: inline-block;
      margin-bottom: 15px;
      color: #232323;
    }

    p {
      margin-bottom: 25px;
    }
  }

  i {
    position: relative;
    display: inline-block;
    font-size: 50px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    padding: 40px;
    margin-bottom: 40px;
    border-radius: 50%;
    overflow: hidden;
    transition: var(--default-transition);
    background-color: #fff;

    @media (max-width:var(--sm)) {
      margin-bottom: 30px;
    }

    @media (max-width:var(--xs)) {
      margin-bottom: 30px;
    }

    &:after {
      content: "";
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(20deg, #2ed47c 0, #e8bf47 100%);
      opacity: 0;
      transition: var(--default-transition);
    }

    &:before {
      position: relative;
      z-index: 1;
    }
  }

  .btn.btn-link {
    color: #232323;

    &:hover {
      opacity: 0.7;
    }

    &::after {
      background-color: #232323;
    }
  }
}

//Icon With Text Style 08

.icon-with-texts {
  .btn.btn-sonar {
    border: 0;
  }
}

.icon-with-text-08 {
  >div {
    align-items: center;
    display: flex;
  }

  i {
    font-size: 35px;
    margin-right: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: #fff;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--default-transition);
  }

  .feature-box-content {
    p {
      margin-bottom: 0;
    }

    .title {
      color: #232323;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  &:hover {
    i {
      background-color: #232323;
      color: #fff;
    }
  }
}

//Icon With Text Style 09
.icon-with-text-09 {
  >div {
    text-align: center;
    justify-content: center;
    align-items: center;
    transition-duration: 0.4s;
    padding: 30px 0;
    border-radius: 6px;

    &:hover {
      transform: translate3d(0, -3px, 0) !important;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      background-color: white;
    }
  }

  .feature-box-content {
    margin: 0 auto;
    line-height: 26px;
    width: 70%;

    @media (max-width:var(--lg)) {
      width: 80%;
    }

    @media (max-width:var(--md)) {
      width: 75%;
    }


    .title {
      color: #374162;
      line-height: 24px;
    }
  }

  i {
    font-size: 32px;
    margin-bottom: 20px;
    display: block;
  }
}

//Icon With Text Style 10
.icon-with-text-10 {
  >div {
    transition: var(--default-transition);
    position: relative;
    text-align: center;
    padding: 4.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width:var(--lg)) {
      padding: 3rem;
    }

    .feature-box-content {
      .title {
        color: #232323;
        display: inline-block;
        margin-bottom: 10px;
      }
    }

    &:hover {
      transform: translate3d(0, -3px, 0);
      border-radius: 6px;
      background-color: #fff;
      border-color: transparent;
      box-shadow: 0 0 20px rgb(0 0 0 / 5%);

      .feature-box-overlay {
        opacity: 1;
      }
    }
  }

  i {
    margin-bottom: 35px;
    font-size: 40px;
    display: inline-block;

    @media (max-width:var(--md)) {
      margin-bottom: 15px;
    }

    @media (max-width:var(--sm)) {
      margin-bottom: 10px;
    }
  }
}

//Icon With Text Style 11
.icon-with-text-11 {
  >div {
    transition: var(--default-transition);
    padding: 4rem;
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);

    i {
      font-size: 50px;
      display: inline-block;
      margin-bottom: 35px;
    }

    .feature-box-content {
      .title {
        font-family: var(--alt-font);
        font-weight: 500;
        margin-bottom: 10px;
        color: #232323;
        display: block;
        font-size: 16px;
        line-height: 28px;
      }

      p {
        margin-bottom: 25px;
      }
    }

    &:hover {
      background: var(--base-color);
      color: #fff;

      i {
        color: #fff;
      }

      .feature-box-content {
        span {
          color: #fff;
        }

        a {
          color: #fff;

          &:after {
            transition: var(--default-transition);
            background-color: #fff;
          }
        }
      }
    }
  }
}