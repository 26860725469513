.mapTitle {
  display: flex;
  justify-content: center;
  padding-bottom: 6%;
  font-size: 1.75;
  font-family: Helvetica;
  color: black;
}

.offCanvas {
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #888;

  .location-title {
    font-size: 24px;
    margin: 0;
  }

  .location-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .location-description {
    font-size: 16px;
  }

  .location-details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .detail {
      width: 48%;
    }

    .detail-label {
      font-weight: bold;
    }
  }

  .location-garrison {
    margin-top: 20px;

    .garrison-item {
      list-style: square;
      margin-left: 20px;
    }
  }
}


.mapContainer {

  display: flex;

  justify-content: space-around;

  align-content: center;
  border-radius: 40rem;
  height: 43vh;



  .leafLetMap {

    width: 700px;

    height: 100vh;

    z-index: 3;

    position: absolute;

    height: 90%;

    width: 95%;

    padding: 5px;

    border-radius: 3rem;

    touch-action: pan-x pan-y;

    .popupContent {
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        font-family: "IT Norms Pro";
        width: 170px;
        font-size: 12px;
      }

      img {
        width: 170px
      }

      .buttonMap {
        align-items: center;
        appearance: none;
        background-image: radial-gradient(100% 100% at 100% 0,
            #318CE7 0,
            #318CE7 100%);
        border: 0;
        border-radius: 6px;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,

          rgba(45, 35, 66, 0.3) 0 7px 13px -3px,

          rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-family: "JetBrains Mono", monospace;
        height: 48px;
        justify-content: center;
        line-height: 1;
        list-style: none;
        overflow: hidden;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow 0.15s, transform 0.15s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        will-change: box-shadow, transform;
        font-size: 8px;
        font-family: "IT Norms Pro";
        &:hover {
       background:#F0F8FF;
       color: black;
        font-size: 10px;
        font-weight: bold;
          transform: translateY(-2px);
        }
      }
    }
  }
}