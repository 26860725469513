.slider {
    width: 100%;
    height: 91vh;
    position: relative;
    overflow: hidden;
}


.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all .5s ease;
}

@media (min-width: 279px) and (max-width: 339px) {
    body .tagline-cont {
    position: absolute;
    text-align: center;
    top: 7rem;
    left: 25%;
    width: 55%;
    background-image: linear-gradient(to bottom right, #002868 ,#BF0A30);
    padding: 10px;
    border-radius: 5px;
    line-height: 5px;
    }

    body .tagline {
        text-align: center;
        font-size: 1.2rem;
        font: bold;
        text-shadow: 3px rgb(0, 0, 0);
        color: white;
    }
    body .tagline2 {
        text-align: center;
        font-size: 1rem;
        font: bold;
        color: whitesmoke;
        text-shadow: 3px rgb(0, 0, 0);
    }
    
    #video {
        visibility: hidden;
        width: 100% !important;
        height: auto !important;
        position: static;
        padding-top:70px;
    }
    body .vid-container {
        height: 25%;
        max-width: 100vw;
        margin: 0rem;      
    }
    .slider {
        width:100%;
        height: 50vh;
        position: relative;
        overflow: hidden;
    }
}

@media (min-width: 339px) and (max-width: 540px) {
    body .tagline-cont {
        position: absolute;
        text-align: center;
        top: 8rem;
        left: 17%;
        width: 70%;
        padding: 0px 5px;
        border-radius: 5px;
        row-gap: 20px;
        height:62%
        }
    
        body .tagline {
            text-align: center;
            font-size: 2.5rem;
            font: bold;
            text-shadow: 3px rgb(0, 0, 0);
            color: white;
        }
        body .tagline2 {
            text-align: center;
            font-size: 1.8rem;
            font: bold;
            color: whitesmoke;
            text-shadow: 3px rgb(0, 0, 0);
        }
        #video {
            width: 100% !important;
            height: auto !important;
            position: absolute;
            top: 70px;
        }
        body .vid-container {
            height: 25%;
            max-width: 100vw;
            margin: 0rem;      
        }
        .slider {
            width:100%;
            height: 50vh;
            position: relative;
            overflow: hidden;
        }
}
@media (min-width: 540px) and (max-width: 767px) {
    body .tagline-cont {
    position: absolute;
    text-align: center;
    top: 10rem;
    left: 25%;
    width: 55%;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 5px;
    }

    body .tagline {
        text-align: center;
        font-size: 2.5rem;
        font: bold;
        text-shadow: 3px rgb(0, 0, 0);
        color: white;
    }

    body .tagline2 {
        text-align: center;
        font-size: 1.8rem;
        font: bold;
        color: whitesmoke;
        text-shadow: 3px rgb(0, 0, 0);
    }
    .slider {
        width:100%;
        height: 70vh;
        position: relative;
        overflow: hidden;
    }
    #video {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 30px;
    }
    
}
@media (min-width: 767px) and (max-width: 1200px) {
    body .tagline-cont {
        position: absolute;
        text-align: center;
        top: 15rem;
        left: 24%;
        width: 55%;
        padding: 15px;
        border-radius: 5px;
    }
    body .tagline {
        font-size: 4.5rem;
        font: bold;
        text-shadow: 3px rgb(0, 0, 0);
        color: white;
    }
    body .tagline2 {
        text-align: center;
        font-size: 3rem;
        font: bold;
        color: whitesmoke;
        text-shadow: 3px rgb(0, 0, 0);
    }
    #video {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 3px;
    }

    body .overlay {
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(43, 49, 62, 0.281);
    }
}

@media (min-width: 1201px) and (max-width: 1600px) {
    body .tagline-cont {
        position: absolute;
        text-align: center;
        top: 15rem;
        left: 25%;
        width: 55%;
        padding: 15px;
        border-radius: 5px;
    }

    body .tagline {
        font-size: 4.5rem;
        font: bold;
        text-shadow: 3px rgb(0, 0, 0);
        color: white;
    }

    body .tagline2 {
        text-align: center;
        font-size: 3rem;
        font: bold;
        color: whitesmoke;
        text-shadow: 3px rgb(0, 0, 0);
    }
    #video {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        bottom:-150px
        
    }
}

.slide video {
    height: 100%;
    position: absolute;
    width: 100%
}

.overlay {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 49, 62, 0.281);
}

.tagline-cont {
    position: absolute;
    text-align: center;
    top: 19rem;
    left: 25%;
    width: 55%;
    padding: 15px;
    border-radius: 5px;
}

.tagline {
    font-size: 4.5rem;
    font: bold;
    text-shadow: 3px rgb(0, 0, 0);
    color: white;
}

.tagline2 {
    text-align: center;
    font-size: 3rem;
    font: bold;
    color: whitesmoke;
    text-shadow: 3px rgb(0, 0, 0);
}




// @media screen and (max-width: 600px) {
//     .tagline {
//         width: 50%
//     }

//     .tagline > * {
//         color: white;
//         font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
//         margin: 0px ;


//     }
// }
.tagline>* {
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0px;

}

.current {
    opacity: 1;
    transform: translateX(0);
}



.vid-container {
    height: 100vh;
    max-width: 100vw;
    margin: 0rem;
}

#video {
    width: 100% !important;
    height: auto !important;
    background-color: rgb(0, 0, 0);
}